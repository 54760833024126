import React, { useState } from 'react';

function AddKhachHang() {
    const [formData, setFormData] = useState({
        TenKH: '',
        Email: '',
        SDT: '',
        DiaChi: '',
        NoiDung: '',
        AccountID: null, // AccountID có thể là null
    });
    const [message, setMessage] = useState('');

    // Hàm xử lý khi form thay đổi
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Hàm submit form để thêm khách hàng
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Kiểm tra bắt buộc cho tên khách hàng và số điện thoại
        if (!formData.TenKH || !formData.SDT) {
            setMessage('Tên khách hàng và số điện thoại là bắt buộc');
            return;
        }

        try {
            const response = await fetch('https://api.gamehay.id.vn/api/khachhang', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.error || 'Đã xảy ra lỗi khi thêm khách hàng');
            }

            setMessage(`Khách hàng đã được thêm thành công với ID: ${result.id}`);
            setFormData({
                TenKH: '',
                Email: '',
                SDT: '',
                DiaChi: '',
                NoiDung: '',
                AccountID: null,
            });
        } catch (error) {
            setMessage(`Lỗi: ${error.message}`);
        }
    };

    return (
        <div className="container">
            <h2>Thêm Khách Hàng</h2>
            {message && <p>{message}</p>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Tên Khách Hàng</label>
                    <input
                        type="text"
                        name="TenKH"
                        value={formData.TenKH}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="Email"
                        value={formData.Email}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>

                <div className="form-group">
                    <label>Số Điện Thoại</label>
                    <input
                        type="text"
                        name="SDT"
                        value={formData.SDT}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Địa Chỉ</label>
                    <input
                        type="text"
                        name="DiaChi"
                        value={formData.DiaChi}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>

                <div className="form-group">
                    <label>Nội Dung</label>
                    <textarea
                        name="NoiDung"
                        value={formData.NoiDung}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>

                <div className="form-group">
                    <label>AccountID (Tùy chọn)</label>
                    <input
                        type="text"
                        name="AccountID"
                        value={formData.AccountID || ''} // Nếu null thì hiển thị chuỗi rỗng
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>

                <button type="submit" className="btn btn-primary">Thêm Khách Hàng</button>
            </form>
        </div>
    );
}

export default AddKhachHang;
