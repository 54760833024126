import React from "react";
import { Link } from "react-router-dom";
function Footer() {
    return (
        <div className="page">
            <footer className="section footer-corporate context-dark">
                <div className="footer-corporate-inset">
                    <div className="container">
                        <div className="row row-40 justify-content-lg-between">
                            <div className="col-sm-6 col-md-12 col-lg-3 col-xl-4">
                                <div className="oh-desktop">
                                    <div className="wow slideInRight" data-wow-delay="0s">
                                        <h6 className="text-spacing-100 text-uppercase">
                                            Liên hệ
                                        </h6>
                                        <ul className="footer-contacts d-inline-block d-sm-block">
                                            <li>
                                                <div className="unit">
                                                    <div className="unit-left">
                                                        <span className="icon fa fa-phone"></span>
                                                    </div>
                                                    <div className="unit-body">
                                                        <a className="link-phone" href="tel:#">
                                                            +1 323-913-4688
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="unit">
                                                    <div className="unit-left">
                                                        <span className="icon fa fa-envelope"></span>
                                                    </div>
                                                    <div className="unit-body">
                                                        <a className="link-aemail" href="mailto:#">
                                                            haiztvn@gmail.com
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="unit">
                                                    <div className="unit-left">
                                                        <span className="icon fa fa-location-arrow"></span>
                                                    </div>
                                                    <div className="unit-body">
                                                        <a className="link-location" href="#">
                                                            Phạm Ngũ Lão, Q.1, TP.HCM, Việt NamPhạm Ngũ Lão, Q.1, TP.HCM, Việt Nam
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-3 col-xl-4">
                                <div className="oh-desktop">
                                    <div className="wow slideInDown" data-wow-delay="0s">
                                        <h6 className="text-spacing-100 text-uppercase">
                                            Tin tức mới
                                        </h6>
                                        {/* <!-- Post Minimal 2--> */}
                                        <article className="post post-minimal-2">
                                            <p className="post-minimal-2-title">
                                                <a href="#">
                                                    Cẩm nang cá nhân: 5 điểm đáng đến nhất trên thế giới.
                                                </a>
                                            </p>
                                            <div className="post-minimal-2-time">
                                                {/* <time datetime="2019-05-04">May 04, 2019</time> */}
                                            </div>
                                        </article>
                                        {/* <!-- Post Minimal 2--> */}
                                        <article className="post post-minimal-2">
                                            <p className="post-minimal-2-title">
                                                <a href="#">
                                                    10 khách sạn hàng đầu: Đánh giá bởi các chuyên gia du lịch.
                                                </a>
                                            </p>
                                            <div className="post-minimal-2-time">
                                                {/* <time datetime="2019-05-04">May 04, 2019</time> */}
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-11 col-md-7 col-lg-5 col-xl-4">
                                <div className="oh-desktop">
                                    <div className="wow slideInLeft" data-wow-delay="0s">
                                        <h6 className="text-spacing-100 text-uppercase">
                                            Liên kết nhanh
                                        </h6>
                                        <ul className="row-6 list-0 list-marked list-marked-md list-marked-secondary list-custom-2">
                                            <li>
                                                <Link to="/contact">Liên hệ</Link>
                                            </li>
                                            <li>
                                                <Link to="/properties">Du lịch</Link>
                                            </li>
                                        </ul>
                                        <div className="group-md group-middle justify-content-sm-start">
                                            <Link className="button button-lg button-primary button-ujarak"
                                                to="/properties">Chọn Tour</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-corporate-bottom-panel">
                    <div className="container">
                        <div className="row justfy-content-xl-space-berween row-10 align-items-md-center2">
                            <div className="col-sm-6 col-md-4 text-sm-right text-md-center">
                                <div>
                                    <ul className="list-inline list-inline-sm footer-social-list-2">
                                        <li>
                                            <a className="icon fa fa-facebook" href="#"></a>
                                        </li>
                                        <li>
                                            <a className="icon fa fa-twitter" href="#"></a>
                                        </li>
                                        <li>
                                            <a className="icon fa fa-google-plus" href="#"></a>
                                        </li>
                                        <li>
                                            <a className="icon fa fa-instagram" href="#"></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 order-sm-first">
                                {/* <!-- Rights--> */}
                                <p className="rights">
                                    <span>&copy;&nbsp;</span>
                                    <span className="copyright-year"></span>
                                    <span>&nbsp;</span>
                                    <span>Wonder Tour</span>. All Rights Reserved. Design by{" "}
                                    <a href="https://www.templatemonster.com">
                                        TemplateMonster
                                    </a>
                                </p>
                            </div>
                            <div className="col-sm-6 col-md-4 text-md-right">
                                <p className="rights">
                                    <a href="#">Privacy Policy</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
