import React, { useState, useEffect } from 'react';

function QuocGiaForm() {
    const [quocGiaData, setQuocGiaData] = useState([]);
    const [newQuocGia, setNewQuocGia] = useState({
        TenQG: '',
        KhuVucId: '',
    });
    const [khuVucData, setKhuVucData] = useState([]);
    const [message, setMessage] = useState('');

    // Lấy danh sách khu vực khi component được mount
    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/khuvuc")
            .then((response) => response.json())
            .then((data) => setKhuVucData(data))
            .catch((error) => console.error("Lỗi khi tải khu vực:", error));
    }, []);

    // Lấy danh sách quốc gia khi component được mount
    useEffect(() => {
        fetchQuocGiaData();
    }, []);

    const fetchQuocGiaData = () => {
        fetch("https://api.gamehay.id.vn/api/quocgia")
            .then((response) => response.json())
            .then((data) => setQuocGiaData(data))
            .catch((error) => console.error("Lỗi khi tải quốc gia:", error));
    };

    // Xử lý việc thêm quốc gia mới
    const handleAddQuocGia = async (e) => {
        e.preventDefault();

        if (!newQuocGia.TenQG || !newQuocGia.KhuVucId) {
            setMessage("Tên quốc gia và khu vực không được bỏ trống");
            return;
        }

        try {
            const response = await fetch('https://api.gamehay.id.vn/api/quocgia', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newQuocGia),
            });

            const result = await response.json();

            if (response.ok) {
                setMessage(result.message);
                setNewQuocGia({ TenQG: '', KhuVucId: '' }); // Reset form
                fetchQuocGiaData(); // Cập nhật lại danh sách quốc gia
            } else {
                setMessage(result.error);
            }
        } catch (error) {
            console.error('Lỗi khi thêm quốc gia:', error);
            setMessage('Đã xảy ra lỗi khi thêm quốc gia.');
        }
    };

    return (
        <div>
            <h2>Danh sách Quốc Gia</h2>

            {/* Form thêm quốc gia */}
            <form onSubmit={handleAddQuocGia}>
                <div className="form-group">
                    <label>Tên Quốc Gia</label>
                    <input
                        type="text"
                        value={newQuocGia.TenQG}
                        onChange={(e) => setNewQuocGia({ ...newQuocGia, TenQG: e.target.value })}
                        className="form-control"
                        placeholder="Nhập tên quốc gia"
                    />
                </div>
                <div className="form-group">
                    <label>Khu Vực</label>
                    <select
                        value={newQuocGia.KhuVucId}
                        onChange={(e) => setNewQuocGia({ ...newQuocGia, KhuVucId: e.target.value })}
                        className="form-control"
                    >
                        <option value="">Chọn khu vực</option>
                        {khuVucData.map((kv) => (
                            <option key={kv.IdKV} value={kv.IdKV}>{kv.TenKV}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Thêm Quốc Gia</button>
            </form>

            {/* Hiển thị thông báo */}
            {message && <p>{message}</p>}

            {/* Bảng hiển thị danh sách quốc gia */}
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>ID Quốc Gia</th>
                        <th>Tên Quốc Gia</th>
                        <th>Khu Vực</th>
                    </tr>
                </thead>
                <tbody>
                    {quocGiaData.length > 0 ? (
                        quocGiaData.map((qg) => (
                            <tr key={qg.IdQG}>
                                <td>{qg.IdQG}</td>
                                <td>{qg.TenQG}</td>
                                <td>{khuVucData.find(kv => kv.IdKV === qg.KhuVucId)?.TenKV || 'Không xác định'}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3">Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default QuocGiaForm;
