import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Page from "./Page";
import Login from "./Login";
import PropertyDetails from "./PropertyDetails";
import ScrollToTop from "./ScrollToTop";
import ProtectedPage from './ProtectedPage';
import HeaderPage from './HeaderPage';  // Import component HeaderPage
import Footer from './Footer';
import Profile from './Profile';
function Router() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');
    if (storedAuth === 'true') {
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* Truyền isAuthenticated và onLogout vào HeaderPage */}
      <HeaderPage isAuthenticated={isAuthenticated} onLogout={handleLogout} />
      <Routes>
        <Route path="/home" element={<Page />} />
        <Route path="/properties" element={<Page />} />
        <Route path="/propertiesdetail/:id" element={<PropertyDetails />} />
        <Route path="/contact" element={<Page />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        {/* <Route
          path="/protected"
          element={isAuthenticated ? <ProtectedPage onLogout={handleLogout} /> : <Navigate to="/login" />}
        /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
