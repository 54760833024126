import React, { useState, useEffect } from 'react';

function KhachHangList() {
    const [khachHangData, setKhachHangData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [khachHangPerPage] = useState(5); // Số lượng khách hàng trên mỗi trang
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchKhachHangData();
    }, []);

    // Hàm lấy dữ liệu khách hàng từ API
    const fetchKhachHangData = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://api.gamehay.id.vn/api/khachhang');
            if (!response.ok) {
                throw new Error('Lỗi khi gọi API');
            }
            const data = await response.json();
            setKhachHangData(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Lọc dữ liệu theo từ khóa tìm kiếm
    const filteredKhachHang = khachHangData.filter(kh =>
        kh.TenKH.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Lấy dữ liệu khách hàng cho trang hiện tại
    const indexOfLastKhachHang = currentPage * khachHangPerPage;
    const indexOfFirstKhachHang = indexOfLastKhachHang - khachHangPerPage;
    const currentKhachHang = filteredKhachHang.slice(indexOfFirstKhachHang, indexOfLastKhachHang);

    // Thay đổi trang
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) {
        return <p>Đang tải dữ liệu...</p>;
    }

    if (error) {
        return <p>Có lỗi xảy ra: {error}</p>;
    }

    return (
        <div className="container">
            <h2>Danh sách Khách Hàng</h2>

            {/* Thanh tìm kiếm */}
            <input
                type="text"
                placeholder="Tìm kiếm khách hàng..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control mb-3"
            />

            {/* Bảng dữ liệu */}
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tên Khách Hàng</th>
                        <th>Email</th>
                        <th>Số Điện Thoại</th>
                        {/* <th>Ngày Sinh</th> */}
                        <th>Địa Chỉ</th>
                        <th>Tiểu sử</th>
                    </tr>
                </thead>
                <tbody>
                    {currentKhachHang.length > 0 ? (
                        currentKhachHang.map((kh) => (
                            <tr key={kh.IdKH}>
                                <td>{kh.IdKH}</td>
                                <td>{kh.TenKH}</td>
                                <td>{kh.Email}</td>
                                <td>{kh.SDT}</td>
                                {/* <td>{kh.NgaySinh}</td> */}
                                <td>{kh.DiaChi}</td>
                                <td>{kh.NoiDung}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7">Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Phân trang */}
            <nav>
                <ul className="pagination">
                    {Array.from({ length: Math.ceil(filteredKhachHang.length / khachHangPerPage) }, (_, i) => (
                        <li key={i + 1} className="page-item">
                            <button onClick={() => paginate(i + 1)} className="page-link">
                                {i + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}

export default KhachHangList;
