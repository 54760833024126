import React, { useState, useEffect } from 'react';

function KhuVucForm() {
    const [khuvucData, setKhuVucData] = useState([]);
    const [newKhuVuc, setNewKhuVuc] = useState(''); // Dùng để lưu dữ liệu khu vực mới
    const [message, setMessage] = useState(''); // Thông báo sau khi thêm khu vực

    // Lấy dữ liệu từ API khi component được mount
    useEffect(() => {
        fetchKhuVucData();
    }, []);

    // Hàm lấy dữ liệu từ API
    const fetchKhuVucData = () => {
        fetch("https://api.gamehay.id.vn/api/khuvuc")
            .then((response) => response.json())
            .then((data) => setKhuVucData(data))
            .catch((error) => console.error("Lỗi khi tải dữ liệu:", error));
    };

    // Hàm thêm khu vực mới
    const handleAddKhuVuc = async (e) => {
        e.preventDefault();

        if (!newKhuVuc) {
            setMessage("Tên khu vực không được bỏ trống");
            return;
        }

        try {
            const response = await fetch('https://api.gamehay.id.vn/api/khuvuc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ TenKV: newKhuVuc }),
            });

            const result = await response.json();

            if (response.ok) {
                setMessage(result.message);
                setNewKhuVuc(''); // Clear form input
                fetchKhuVucData(); // Cập nhật danh sách khu vực sau khi thêm
            } else {
                setMessage(result.error);
            }
        } catch (error) {
            console.error('Lỗi khi thêm dữ liệu:', error);
            setMessage('Đã xảy ra lỗi khi thêm khu vực.');
        }
    };
    
    return (
        <div>
            <h2>Danh sách Khu Vực</h2>

            {/* Form thêm khu vực mới */}
            <form onSubmit={handleAddKhuVuc}>
                <div className="form-group">
                    <label>Thêm Khu Vực Mới</label>
                    <input
                        type="text"
                        value={newKhuVuc}
                        onChange={(e) => setNewKhuVuc(e.target.value)}
                        className="form-control"
                        placeholder="Nhập tên khu vực"
                    />
                </div>
                <button type="submit" className="btn btn-primary">Thêm Khu Vực</button>
            </form>

            {/* Hiển thị thông báo sau khi thêm */}
            {message && <p>{message}</p>}

            {/* Bảng hiển thị danh sách khu vực */}
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>ID Khu Vực</th>
                        <th>Tên Khu Vực</th>
                    </tr>
                </thead>
                <tbody>
                    {khuvucData.length > 0 ? (
                        khuvucData.map((kv) => (
                            <tr key={kv.IdKV}>
                                <td>{kv.IdKV}</td>
                                <td>{kv.TenKV}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2">Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default KhuVucForm;
