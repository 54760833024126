import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
function Home() {
  const [products, setPr] = useState([]);

  useEffect(() => {
    fetch("https://api.gamehay.id.vn/api/tourdl")
      .then((response) => response.json())
      .then((data) => {
        setPr(data);
      })
      .catch((error) => console.error("Lỗi:", error));
  }, []);

  return (
    <div>
      <div className="ie-panel">
        <a href="https://windows.microsoft.com/en-US/internet-explorer/">
          <img
            src="images/ie8-panel/warning_bar_0000_us.jpg"
            height="42"
            width="820"
            alt="You are using an outdated browser. For a faster, safer browsing experience, upgrade for free today."
          />
        </a>
      </div>
      <div className="page">
        {/* <ImageSlider /> */}
        {/* <!-- Section Box Categories--> */}
        <section className="section section-lg section-top-1 bg-gray-4">
          <div className="container offset-negative-1">
            <div className="box-categories cta-box-wrap">
              <div className="box-categories-content">
                <div className="row justify-content-center">
                  <div
                    className="col-md-4 wow fadeInDown col-9"
                    data-wow-delay=".2s"
                  >
                    <ul className="list-marked-2 box-categories-list">
                      <li>
                        <a href="#">
                          <img
                            src="images/1.png"
                            alt=""
                            width="368"
                            height="420"
                          />
                        </a>
                        <h5 className="box-categories-title">
                          Du lịch thành phố
                        </h5>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-md-4 wow fadeInDown col-9"
                    data-wow-delay=".2s"
                  >
                    <ul className="list-marked-2 box-categories-list">
                      <li>
                        <a href="#">
                          <img
                            src="images/2.png"
                            alt=""
                            width="368"
                            height="420"
                          />
                        </a>
                        <h5 className="box-categories-title">
                          Du lịch biển
                        </h5>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="col-md-4 wow fadeInDown col-9"
                    data-wow-delay=".2s"
                  >
                    <ul className="list-marked-2 box-categories-list">
                      <li>
                        <a href="#">
                          <img
                            src="images/3.png"
                            alt=""
                            width="368"
                            height="420"
                          />
                        </a>
                        <h5 className="box-categories-title">Du lịch thiên nhiên</h5>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <Link to="/properties" className="link-classic wow fadeInUp">
              Xem thêm chi tiết<span></span>
            </Link>

            {/* <!-- Owl Carousel--> */}
          </div>
        </section>
        {/* <!-- Discover New Horizons--> */}
        <section className="section section-sm section-first bg-default text-md-left">
          <div className="container">
            <div className="row row-50 align-items-center justify-content-center justify-content-xl-between">
              <div className="col-lg-6 text-center wow fadeInUp">
                <img
                  src="images/img-1.png"
                  alt=""
                  width="556"
                  height="382"
                />
              </div>
              <div className="col-lg-6 wow fadeInRight" data-wow-delay=".1s">
                <div className="box-width-lg-470">
                  <h3>Nhứng thứ chúng tôi sẽ mang đến cho bạn</h3>
                  {/* <!-- Bootstrap tabs--> */}
                  <div
                    className="tabs-custom tabs-horizontal tabs-line tabs-line-big tabs-line-style-2 text-center text-md-left"
                    id="tabs-7"
                  >
                    {/* <!-- Nav tabs--> */}
                    <ul className="nav nav-tabs">
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          href="#tabs-7-1"
                          data-toggle="tab"
                        >
                          Du lịch
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          href="#tabs-7-2"
                          data-toggle="tab"
                        >
                          Trải nghiệm
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          className="nav-link"
                          href="#tabs-7-3"
                          data-toggle="tab"
                        >
                          Khám phá
                        </a>
                      </li>
                    </ul>
                    {/* <!-- Tab panes--> */}
                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="tabs-7-1">
                        <p>
                          Chúng tôi sẽ cung cấp cho khách hàng chung tôi các chuyến du
                          lịch tuyệt vời đến những quốc gia có nhiều vẻ đẹp mới lạ. Đến
                          với chung tôi bạn sẽ có những phút thư giãn khi được đi du lịch.
                        </p>
                        <div className="group-md group-middle">
                          <a
                            className="button button-secondary button-pipaluk"
                            href="contact-us.html"
                          >
                            Trải nghiệm du lịch
                          </a>
                          <Link to="/properties" className="button button-black-outline button-md">
                            Xem thêm
                          </Link>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tabs-7-2">
                        <p>
                          Điều tuyệt vời nhất khi đi du lịch là trải nghiệm. Chúng tôi xin
                          hứa sẽ đem lại nhưng trải tốt nhất cho khách hàng.
                        </p>
                        <div className="group-md group-middle">
                          <a
                            className="button button-secondary button-pipaluk"
                            href="contact-us.html"
                          >
                            Trải nghiệm du lịch
                          </a>
                          <Link to="/properties" className="button button-black-outline button-md">
                            Xem thêm
                          </Link>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tabs-7-3">
                        <p>
                          Du lịch khám phá đang là xu hướng được nhiều người yêu thích hiện nay.
                          Là sự lựa chọn hoàn hảo cho những tâm hồn yêu thích tự do và phiêu lưu.
                          Du khách có thể tự mình trải nghiệm những điều thú vị, khám phá những
                          nét văn hóa độc đáo.
                        </p>
                        <div className="group-md group-middle">
                          <a
                            className="button button-secondary button-pipaluk"
                            href="contact-us.html"
                          >
                            Trải nghiệm du lịch
                          </a>
                          <Link to="/properties" className="button button-black-outline button-md">
                            Xem thêm
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--	Our Services--> */}
        <section className="section section-sm">
          <div className="container">
            <h3>Dịch Vụ</h3>
            <div className="row row-30">
              <div className="col-sm-6 col-lg-4">
                <article className="box-icon-classic">
                  <div className="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                    <div className="unit-left">
                      <div className="box-icon-classic-icon fl-bigmug-line-equalization3"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="box-icon-classic-title">
                        <a href="#">Kết hợp được cá nhân hóa</a>
                      </h5>
                      <p className="box-icon-classic-text">
                        Hệ thống kết hợp độc đáo của chúng tôi cho phép bạn chỉ tìm thấy tour
                        du lịch bạn muốn cho kỳ nghỉ tiếp theo của mình.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-sm-6 col-lg-4">
                <article className="box-icon-classic">
                  <div className="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                    <div className="unit-left">
                      <div className="box-icon-classic-icon fl-bigmug-line-circular220"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="box-icon-classic-title">
                        <a href="#">
                          Nhiều loại tour du lịch</a>
                      </h5>
                      <p className="box-icon-classic-text">
                        Chúng tôi cung cấp một loạt các tour du lịch
                        được lựa chọn cá nhân với các điểm đến trên toàn cầu.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-sm-6 col-lg-4">
                <article className="box-icon-classic">
                  <div className="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                    <div className="unit-left">
                      <div className="box-icon-classic-icon fl-bigmug-line-favourites5"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="box-icon-classic-title">
                        <a href="#">
                          Dịch vụ có trình độ cao</a>
                      </h5>
                      <p className="box-icon-classic-text">
                        Quản lý tour của chúng tôi có trình độ, tay nghề cao và
                        thân thiện để mang đến cho bạn dịch vụ tốt nhất.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-sm-6 col-lg-4">
                <article className="box-icon-classic">
                  <div className="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                    <div className="unit-left">
                      <div className="box-icon-classic-icon fl-bigmug-line-headphones32"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="box-icon-classic-title">
                        <a href="#">
                          Hỗ trợ 24/7</a>
                      </h5>
                      <p className="box-icon-classic-text">
                        Bạn luôn có thể nhận được sự hỗ trợ chuyên nghiệp từ nhân viên của
                        chúng tôi 24/7 và hỏi bất kỳ câu hỏi nào bạn có.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-sm-6 col-lg-4">
                <article className="box-icon-classic">
                  <div className="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                    <div className="unit-left">
                      <div className="box-icon-classic-icon fl-bigmug-line-hot67"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="box-icon-classic-title">
                        <a href="#">Khách sạn được lựa chọn cẩn thận</a>
                      </h5>
                      <p className="box-icon-classic-text">
                        Nhóm của chúng tôi chỉ cung cấp sự lựa chọn tốt nhất của các khách sạn giá cả
                        phải chăng và sang trọng cho khách hàng của chúng tôi.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
              <div className="col-sm-6 col-lg-4">
                <article className="box-icon-classic">
                  <div className="unit box-icon-classic-body flex-column flex-md-row text-md-left flex-lg-column text-lg-center flex-xl-row text-xl-left">
                    <div className="unit-left">
                      <div className="box-icon-classic-icon fl-bigmug-line-wallet26"></div>
                    </div>
                    <div className="unit-body">
                      <h5 className="box-icon-classic-title">
                        <a href="#">Đảm bảo giá tốt nhất</a>
                      </h5>
                      <p className="box-icon-classic-text">
                        Nếu bạn tìm thấy các tour du lịch rẻ hơn chúng tôi,
                        chúng tôi sẽ bù đắp phần chênh lệch.
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Hot tours--> */}
        <section className="section section-sm bg-default">
          <div className="container">
            <h3 className="oh-desktop">
              <span className="d-inline-block wow slideInDown">Lượt Đánh Giá Cao</span>
            </h3>
            <div className="row row-sm row-40 row-md-50">
              {products.slice(0, 2).map((product) => (
                <div className="col-sm-6 col-md-12 wow fadeInRight">
                  {/* <!-- Product Big--> */}
                  <article className="product-big">
                    <div className="unit flex-column flex-md-row align-items-md-stretch">
                      <div className="unit-left">
                        <a className="product-big-figure" href="#">
                          {/* <img
                            src="images/product-big-1-600x366.jpg"
                            alt=""
                            width="600"
                            height="366"
                          /> */}
                          <img
                            src={require(`./images1/tourdl/${product.TourImage}`)}
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="unit-body">
                        <div className="product-big-body">
                          <h5 className="product-big-title">
                            <a href="#">{product.TourName}</a>
                          </h5>
                          <div className="group-sm group-middle justify-content-start">
                            <div className="product-big-rating">
                              <span className="icon material-icons-star"></span>
                              <span className="icon material-icons-star"></span>
                              <span className="icon material-icons-star"></span>
                              <span className="icon material-icons-star"></span>
                              <span className="icon material-icons-star_half"></span>
                            </div>
                            <a className="product-big-reviews" href="#">
                              4 customer reviews
                            </a>
                          </div>
                          <p className="product-big-text">
                            {product.TourDetail}
                          </p>
                          <a
                            className="button button-black-outline button-ujarak"
                            href="#"
                          >
                            <Link to={`/propertiesdetail/${product.TourId}`}>
                              Xem chi tiết
                            </Link>
                          </a>
                          <div className="product-big-price-wrap">
                            <span className="product-big-price">${product.TourPrice}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              ))}

            </div>
          </div>
        </section>
        {/* <!-- Different People--> */}
        <section className="section section-sm">
          <div className="container">
            <h3 className="title-block find-car oh">
              <span className="d-inline-block wow slideInUp">
                Nhân Viên Hỗ Trợ - Tư Vấn
              </span>
            </h3>
            <div className="row row-30 justify-content-center box-ordered">
              <div className="col-sm-6 col-md-5 col-lg-3">
                {/* <!-- Team Modern--> */}
                <article className="team-modern">
                  <div className="team-modern-header">
                    <a className="team-modern-figure" href="#">
                      <img
                        className="img-circles"
                        src="images/user3.png"
                        alt=""
                        width="118"
                        height="118"
                      />
                    </a>
                    {/* <svg
                      x="0px"
                      y="0px"
                      width="270px"
                      height="70px"
                      viewbox="0 0 270 70"
                      enable-background="new 0 0 270 70"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          fill="#161616"
                          d="M202.085,0C193.477,28.912,166.708,50,135,50S76.523,28.912,67.915,0H0v70h270V0H202.085z"
                        ></path>
                      </g>
                    </svg> */}
                  </div>
                  <div className="team-modern-caption">
                    <h6 className="team-modern-name">
                      <a href="#">Nguyễn Văn A</a>
                    </h6>
                    <p className="team-modern-status">Nhân viên tư vấn</p>
                    <h6 className="team-modern-phone">
                      <a href="tel:#">+1 323-913-4688</a>
                    </h6>
                  </div>
                </article>
              </div>
              <div className="col-sm-6 col-md-5 col-lg-3">
                {/* <!-- Team Modern--> */}
                <article className="team-modern">
                  <div className="team-modern-header">
                    <a className="team-modern-figure" href="#">
                      <img
                        className="img-circles"
                        src="images/user2.png"
                        alt=""
                        width="118"
                        height="118"
                      />
                    </a>
                    {/* <svg
                      x="0px"
                      y="0px"
                      width="270px"
                      height="70px"
                      viewbox="0 0 270 70"
                      enable-background="new 0 0 270 70"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          fill="#161616"
                          d="M202.085,0C193.477,28.912,166.708,50,135,50S76.523,28.912,67.915,0H0v70h270V0H202.085z"
                        ></path>
                      </g>
                    </svg> */}
                  </div>
                  <div className="team-modern-caption">
                    <h6 className="team-modern-name">
                      <a href="#">Nguyễn Văn B</a>
                    </h6>
                    <p className="team-modern-status">Hướng dẫn viên</p>
                    <h6 className="team-modern-phone">
                      <a href="tel:#">+1 323-913-4688</a>
                    </h6>
                  </div>
                </article>
              </div>
              <div className="col-sm-6 col-md-5 col-lg-3">
                {/* <!-- Team Modern--> */}
                <article className="team-modern">
                  <div className="team-modern-header">
                    <a className="team-modern-figure" href="#">
                      <img
                        className="img-circles"
                        src="images/user3.png"
                        alt=""
                        width="118"
                        height="118"
                      />
                    </a>
                    {/* <svg
                      x="0px"
                      y="0px"
                      width="270px"
                      height="70px"
                      viewbox="0 0 270 70"
                      enable-background="new 0 0 270 70"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          fill="#161616"
                          d="M202.085,0C193.477,28.912,166.708,50,135,50S76.523,28.912,67.915,0H0v70h270V0H202.085z"
                        ></path>
                      </g>
                    </svg> */}
                  </div>
                  <div className="team-modern-caption">
                    <h6 className="team-modern-name">
                      <a href="#">Nguyễn Văn C</a>
                    </h6>
                    <p className="team-modern-status">Nhân viên kế toán</p>
                    <h6 className="team-modern-phone">
                      <a href="tel:#">+1 323-913-4688</a>
                    </h6>
                  </div>
                </article>
              </div>
              <div className="col-sm-6 col-md-5 col-lg-3">
                {/* <!-- Team Modern--> */}
                <article className="team-modern">
                  <div className="team-modern-header">
                    <a className="team-modern-figure" href="#">
                      <img
                        className="img-circles"
                        src="images/user1.png"
                        alt=""
                        width="118"
                        height="118"
                      />
                    </a>
                    {/* <svg
                      x="0px"
                      y="0px"
                      width="270px"
                      height="70px"
                      viewbox="0 0 270 70"
                      enable-background="new 0 0 270 70"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          fill="#161616"
                          d="M202.085,0C193.477,28.912,166.708,50,135,50S76.523,28.912,67.915,0H0v70h270V0H202.085z"
                        ></path>
                      </g>
                    </svg> */}
                  </div>
                  <div className="team-modern-caption">
                    <h6 className="team-modern-name">
                      <a href="#">Nguyễn Văn D</a>
                    </h6>
                    <p className="team-modern-status">Quản lý</p>
                    <h6 className="team-modern-phone">
                      <a href="tel:#">+1 323-913-4688</a>
                    </h6>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Section Subscribe--> */}
        {/* <section className="section bg-default text-center offset-top-50">
          <div
            className="parallax-container"
            data-parallax-img="images/parallax-1-1920x850.jpg"
          >
            <div className="parallax-content section-xl section-inset-custom-1 context-dark bg-overlay-2-21">
              <div className="container">
                <h2 className="heading-2 oh font-weight-normal wow slideInDown">
                  <span className="d-block font-weight-semi-bold">
                    First-class Impressions
                  </span>
                  <span className="d-block font-weight-light">
                    are Waiting for You!
                  </span>
                </h2>
                <p
                  className="text-width-medium text-spacing-75 wow fadeInLeft"
                  data-wow-delay=".1s"
                >
                  Our agency offers travelers various tours and excursions with
                  destinations all over the world. Browse our website to find
                  your dream tour!
                </p>
                <a className="button button-secondary button-pipaluk" href="#">
                  Book a Tour Now
                </a>
              </div>
            </div>
          </div>
        </section> */}
      </div>
      {/* <!-- Global Mailform Output--> */}
      <div className="snackbars" id="form-output-global"></div>
    </div>
  );
}

export default Home;
