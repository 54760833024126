import React, { useState, useEffect } from 'react';

function AccountList() {
    const [accountData, setAccountData] = useState([]); // Dữ liệu tài khoản
    const [searchTerm, setSearchTerm] = useState(''); // Từ khóa tìm kiếm
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại
    const [accountsPerPage] = useState(5); // Số lượng tài khoản trên mỗi trang
    const [loading, setLoading] = useState(true); // Trạng thái đang tải
    const [error, setError] = useState(null); // Lỗi nếu có

    // Gọi API để lấy danh sách tài khoản khi component được render
    useEffect(() => {
        fetchAccountData();
    }, []);

    // Hàm gọi API để lấy dữ liệu tài khoản
    const fetchAccountData = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://api.gamehay.id.vn/api/account');
            if (!response.ok) {
                throw new Error('Lỗi khi gọi API');
            }
            const data = await response.json();
            setAccountData(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Hàm thay đổi trang
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Lọc dữ liệu tài khoản theo từ khóa tìm kiếm
    const filteredAccounts = accountData.filter(account =>
        account.AccountName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Lấy dữ liệu tài khoản cho trang hiện tại
    const indexOfLastAccount = currentPage * accountsPerPage;
    const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
    const currentAccounts = filteredAccounts.slice(indexOfFirstAccount, indexOfLastAccount);

    if (loading) {
        return <p>Đang tải dữ liệu...</p>;
    }

    if (error) {
        return <p>Có lỗi xảy ra: {error}</p>;
    }

    return (
        <div className="container">
            <h2>Danh sách Tài Khoản</h2>

            {/* Thanh tìm kiếm */}
            <input
                type="text"
                placeholder="Tìm kiếm tài khoản..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control mb-3"
            />

            {/* Bảng dữ liệu tài khoản */}
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tên tài khoản</th>
                        <th>Email</th>
                        <th>Vai trò</th>
                    </tr>
                </thead>
                <tbody>
                    {currentAccounts.length > 0 ? (
                        currentAccounts.map((account) => (
                            <tr key={account.id}>
                                <td>{account.ID}</td>
                                <td>{account.AccountName}</td>
                                <td>{account.Mail}</td>
                                <td>{account.Role}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">Không có tài khoản nào</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Phân trang */}
            <nav>
                <ul className="pagination">
                    {Array.from({ length: Math.ceil(filteredAccounts.length / accountsPerPage) }, (_, i) => (
                        <li key={i + 1} className="page-item">
                            <button onClick={() => paginate(i + 1)} className="page-link">
                                {i + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}

export default AccountList;
