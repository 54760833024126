import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

function Properties() {
    const [products, setPr] = useState([]);
    const [theloaidl, setTL] = useState([]);
    const [quocgia, setQG] = useState([]);
    const [IdTL, setSelectedMaL] = useState(0);
    const [IdQG, setSelectedIdQG] = useState(0);
    const [sortOrder, setSortOrder] = useState("desc"); // Trạng thái sắp xếp ban đầu
    const [itemsToShow, setItemsToShow] = useState(2); // Hiển thị 4 sản phẩm ban đầu
    const [searchTerm, setSearchTerm] = useState('');

    // dữ liệu tuordl
    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/tourdl")
            .then((response) => response.json())
            .then((data) => {
                setPr(data);
            })
            .catch((error) => console.error("Lỗi:", error));
    }, []);
    // dữ liệu thể loại du lịch
    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/theloaidl")
            .then((response) => response.json())
            .then((data) => {
                setTL(data);
            })
            .catch((error) => console.error("Lỗi:", error));
    }, []);
    // dữ liệu quốc gia
    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/quocgia")
            .then((response) => response.json())
            .then((data) => {
                setQG(data);
            })
            .catch((error) => console.error("Lỗi:", error));
    }, []);

    // Sắp xếp danh sách sản phẩm dựa trên sortOrder
    const sortProducts = (products) => {
        return products.sort((a, b) => {
            if (sortOrder === "asc") {
                return a.TourId - b.TourId;
            } else {
                return b.TourId - a.TourId;
            }
        });
    };
    const filteredProducts = products.filter((product) => {
        // Điều kiện tìm kiếm theo tên sản phẩm
        if (!product.TourName.toLowerCase().includes(searchTerm.toLowerCase())) {
            return false;
        }

        // Điều kiện 1: Nếu IdTL = 0 và IdQG = 0, hiển thị tất cả sản phẩm
        if (IdTL === 0 && IdQG === 0) {
            console.log("1");
            return true;
        }

        // Điều kiện 2: Nếu IdTL !== 0 và IdQG = 0, hiển thị sản phẩm theo TouTL
        if (IdTL === product.TouTL && IdQG === 0) {
            console.log("2");
            return true;
        }

        // Điều kiện 3: Nếu IdQG !== 0, hiển thị sản phẩm có cả TouTL và TouQG tương ứng
        if (product.TouQG === IdQG && product.TouTL === IdTL) {
            console.log("3");
            return true;
        }

        // Điều kiện 4: Nếu IdTL = 0 nhưng IdQG trùng với TouQG, hiển thị sản phẩm
        if (IdTL === 0 && IdQG === product.TouQG) {
            return true;
        }

        // Nếu không thỏa điều kiện nào, không hiển thị sản phẩm
        return false;
    });

    const filteredSortProducts = sortProducts(filteredProducts)

    // Hiển thị các sản phẩm từ đầu đến itemsToShow
    const currentItems = filteredSortProducts.slice(0, itemsToShow);

    const handleDropdownItemClickTL = (IdTL) => {
        setSelectedMaL(IdTL);
        setItemsToShow(2); // Reset lại số lượng sản phẩm khi chọn thể loại mới
    };
    const handleDropdownItemClickQG = (IdQG) => {
        setSelectedIdQG(IdQG);
    };
    const handleLoadMore = () => {
        setItemsToShow(itemsToShow + 2); // Mỗi lần bấm thêm sản phẩm
    };
    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value); // Cập nhật trạng thái sắp xếp theo tùy chọn người dùng
    };

    return (
        <div className="section properties">
            <div className="container">
                <ul className="properties-filter">
                    <li>
                        <a
                            className={classNames({ is_active: 0 === IdTL })}
                            onClick={() => handleDropdownItemClickTL(0)}
                            data-filter="*"
                        >
                            Tất cả
                        </a>
                    </li>
                    {theloaidl.map((theloai) => (
                        <li key={theloai.IdTL}>
                            <a
                                className={classNames({ is_active: IdTL === theloai.IdTL })}
                                onClick={() => handleDropdownItemClickTL(theloai.IdTL)}
                                data-filter="*"
                            >
                                {theloai.TenTL}
                            </a>
                        </li>
                    ))}
                </ul>
                <div>
                    <input
                        type="text"
                        placeholder="Tìm kiếm tour..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control mb-3"
                    />
                </div>
                {/* Listbox sắp xếp */}
                <div className="listbox">
                    <div className="col-lg-12 text-center">
                        <select className="form-select" value={sortOrder} onChange={handleSortOrderChange}>
                            <option value="asc">Ngày đăng cũ nhất</option>
                            <option value="desc">Ngày đăng mới nhất</option>
                        </select>
                    </div>
                    <div className="col-lg-12 text-center">
                        <select
                            className="form-select"
                            value={IdQG} // Biến state lưu giá trị số của quốc gia đã chọn
                            onChange={(e) => handleDropdownItemClickQG(Number(e.target.value))} // Chuyển giá trị thành số
                        >
                            <option key={0} value={0}>
                                Quốc gia
                            </option>
                            {quocgia.map((quocgia) => (
                                <option key={quocgia.IdQG} value={quocgia.IdQG}>
                                    {quocgia.TenQG}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row properties-box">
                    {currentItems.map((product) => (
                        <div
                            className="col-lg-4 col-md-6 align-self-center mb-30 properties-items col-md-6 adv"
                            key={product.TourId}
                        >
                            <Link to={`/propertiesdetail/${product.TourId}`}>
                                <div className="item">
                                    <a href="property-details.html">
                                        <img
                                            src={require(`./images1/tourdl/${product.TourImage}`)}
                                            alt=""
                                        />
                                    </a>
                                    <span className="category">
                                        {theloaidl.find((theloai) => theloai.IdTL === product.TouTL)
                                            ?.TenTL || "Thể loại không xác định"}
                                    </span>
                                    <h6>${product.TourPrice}</h6>
                                    <h4>
                                        <a href="property-details.html">{product.TourName}</a>
                                    </h4>
                                    <ul>
                                        <li>
                                            Thời hạn: <span>{new Date(product.TourTime).toLocaleDateString('vi-VN')}</span>
                                        </li>
                                        <li>
                                            Số ngày đi: <span>{product.TourDay}</span>
                                        </li>
                                    </ul>
                                    <div className="main-button">
                                        <Link to={`/propertiesdetail/${product.TourId}`}>
                                            Xem chi tiết
                                        </Link>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>

                {/* Nút Xem thêm */}
                {itemsToShow < filteredProducts.length && (
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <button className="btn btn-primary" onClick={handleLoadMore}>
                                Xem thêm
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Properties;
