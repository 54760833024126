import styles from "./Navbar.module.css";
import "./ImageSlider.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import logo from './images1/tourdl/logo-default-450x37.png';

function HeaderPage({ isAuthenticated, onLogout }) {  // Nhận isAuthenticated từ props
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();  // Gọi hàm đăng xuất để xóa trạng thái
    navigate("/login");  // Chuyển hướng về trang login
  };

  useEffect(() => {
    setActiveLink(location.pathname);

    const handleScroll = () => {
      setScrolled(window.scrollY > 150); // Thay đổi giá trị này để điều chỉnh khi navbar được ẩn/hiện
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  return (
    <div>
      <div className="sub-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <ul className="info">
                <li><i className="fa fa-envelope"></i>haiztvn@gmail.com</li>
                <li><i className="fa fa-map"></i>Phạm Ngũ Lão, Q.1, TP.HCM, Việt Nam</li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-4">
              <ul className="social-links">
                <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                <li><a href="https://x.com/minthu" target="_blank"><i className="fab fa-twitter"></i></a></li>
                <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                <li><a href="#"><i className="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.headerSection}>
            <div className={styles.headerSection1}>
              <a className="brand" href="/home">
                <img
                  className={styles.logo}
                  src={logo}
                  alt=""
                />
              </a>
            </div>
            <div className={styles.headerSection2}>
              <ul className="rd-navbar-corporate-contacts">
                <li>
                  <div className="unit unit-spacing-xs">
                    <div className="unit-left">
                      <span className="icon fa fa-clock-o"></span>
                    </div>
                    <div className="unit-body">
                      <p>
                        09:00<span>am</span> — 05:00<span>pm</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="unit unit-spacing-xs">
                    <div className="unit-left">
                      <span className="icon fa fa-phone"></span>
                    </div>
                    <div className="unit-body">
                      <a className="link-phone" href="tel:#">
                        +1 323-913-4688
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <nav className={classNames(styles.nav, { [styles.scrolled]: scrolled })}>
          <ul>
            <li>
              <Link
                to="/home"
                className={classNames(styles.link, {
                  [styles.active]: activeLink === "/home",
                })}
              >
                Trang chủ
              </Link>
            </li>
            <li>
              <Link
                to="/properties"
                className={classNames(styles.link, {
                  [styles.active]: activeLink === "/properties",
                })}
              >
                Du lịch
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={classNames(styles.link, {
                  [styles.active]: activeLink === "/contact",
                })}
              >
                Liên hệ
              </Link>
            </li>
            {isAuthenticated ? (
              <>
                <li>
                  <Link
                    to="/profile"
                    className={classNames(styles.link, {
                      [styles.active]: activeLink === "/profile",
                    })}
                  >
                    Tài khoản
                  </Link>
                </li>
                <li>
                  <Link
                    to="/home"
                    onClick={handleLogout}  // Gọi hàm logout khi người dùng bấm "Logout"
                    className={classNames(styles.link)}
                  >
                    Đăng xuất
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <Link
                  to="/login"
                  className={classNames(styles.link, {
                    [styles.active]: activeLink === "/login",
                  })}
                >
                  Đăng nhập
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default HeaderPage;
