import React, { useState, useEffect } from 'react';

const CartDetails = () => {
    const [cartItems, setCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCartData = async () => {
            try {
                const response = await fetch(`https://api.gamehay.id.vn/api/giohang/1`); // Thay 1 bằng GioHangID thực tế
                if (!response.ok) {
                    throw new Error('Lỗi khi tải giỏ hàng.');
                }
                const data = await response.json();
                setCartItems(data);
                setLoading(false);
            } catch (err) {
                setError('Lỗi khi tải giỏ hàng.');
                setLoading(false);
            }
        };
        fetchCartData();
    }, []);

    const increaseQuantity = async (chiTietGioHangId) => {
        try {
            const response = await fetch(`https://api.gamehay.id.vn/api/giohang/increase/${chiTietGioHangId}`, {
                method: 'PUT',
            });
            if (response.ok) {
                // Trong hàm increaseQuantity và decreaseQuantity
                setCartItems((prevItems) =>
                    prevItems.map((item) =>
                        item.ChiTietGioHangID === chiTietGioHangId
                            ? { ...item, SoLuongVe: item.SoLuongVe + 1 } // hoặc -1 cho decrease
                            : item
                    )
                );
            } else {
                console.error('Lỗi khi tăng số lượng vé');
            }
        } catch (error) {
            console.error('Lỗi khi tăng số lượng vé:', error);
        }
    };

    const decreaseQuantity = async (chiTietGioHangId) => {
        try {
            const response = await fetch(`https://api.gamehay.id.vn/api/giohang/decrease/${chiTietGioHangId}`, {
                method: 'PUT',
            });
            if (response.ok) {
                setCartItems((prevItems) =>
                    prevItems.map((item) =>
                        item.ChiTietGioHangID === chiTietGioHangId && item.SoLuongVe > 1
                            ? { ...item, SoLuongVe: item.SoLuongVe - 1 }
                            : item
                    )
                );
            } else {
                console.error('Lỗi khi giảm số lượng vé');
            }
        } catch (error) {
            console.error('Lỗi khi giảm số lượng vé:', error);
        }
    };

    const cancelItem = async (chiTietGioHangId) => {
        try {
            const response = await fetch(`https://api.gamehay.id.vn/api/giohang/delete/${chiTietGioHangId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setCartItems((prevItems) => prevItems.filter((item) => item.ChiTietGioHangID !== chiTietGioHangId));
            } else {
                console.error('Lỗi khi xóa vé');
            }
        } catch (error) {
            console.error('Lỗi khi xóa vé:', error);
        }
    };



    if (loading) return <p>Đang tải...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="container">
            <h2>Giỏ hàng</h2>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Tên Tour</th>
                        <th>Giá Vé</th>
                        <th>Số Lượng</th>
                        <th>Tổng</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems.map((item) => (
                        <tr key={item.ChiTietGioHangID}>
                            <td>{item.TourName}</td>
                            <td>{item.GiaVe} $</td>
                            <td>
                                <button onClick={() => decreaseQuantity(item.ChiTietGioHangID)}>-</button>
                                {item.SoLuongVe}
                                <button onClick={() => increaseQuantity(item.ChiTietGioHangID)}>+</button>
                            </td>
                            <td>{item.GiaVe * item.SoLuongVe} $</td>
                            <td>
                                <button onClick={() => cancelItem(item.ChiTietGioHangID)}>Hủy</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CartDetails;
