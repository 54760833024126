import React, { useState } from 'react';
import styles from './Signin.module.css';
import './global.css';  // Importing the global CSS
import { Link, useNavigate } from "react-router-dom";

function Login({ onLogin }) {
    const [isActive, setIsActive] = useState(false);
    const [formData, setFormData] = useState({ AccountName: "", AccountPassword: "", Mail: "" });
    const [message, setMessage] = useState(""); // To store messages from API
    const navigate = useNavigate();  // Sử dụng useNavigate

    const handleRegisterClick = () => {
        setIsActive(true);
    };

    const handleLoginClick = () => {
        setIsActive(false);
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("https://api.gamehay.id.vn/api/account", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            if (response.ok) {
                setMessage("Đăng ký thành công");
            } else {
                setMessage(data.error || "Có lỗi xảy ra");
            }
        } catch (error) {
            console.error("Lỗi khi gửi dữ liệu:", error);
            setMessage("Lỗi khi gửi dữ liệu");
        }
    };

    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("https://api.gamehay.id.vn/api/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const data = await response.json();
            console.log('API Response:', data); // Check what data is being returned
            if (response.ok) {
                // Check if the user data exists
                if (data.user) {
                    console.log('User data:', data.user);
                    localStorage.setItem('userInfo', JSON.stringify(data.user));
                } else {
                    console.error('User data is missing in response:', data);
                }
                onLogin(); // Call onLogin prop
                navigate("/profile"); // Redirect to profile page
                setMessage("Đăng nhập thành công");
            } else {
                setMessage(data.error || "Có lỗi xảy ra");
            }
        } catch (error) {
            console.error("Lỗi khi gửi dữ liệu:", error);
            setMessage("Lỗi khi gửi dữ liệu");
        }
    };
    return (
        <div className="body"> {/* Applying the .body class */}
            <div className={`${styles.container} ${isActive ? styles.active : ''}`} id="container">
                <div className={`${styles['form-container']} ${styles['sign-up']}`}>
                    <form onSubmit={handleSubmit}>
                        <h1>Create Account</h1>
                        <div className={styles['social-icons']}>
                            <a href="#" className={styles.icon}><i className="fa-brands fa-google-plus-g"></i></a>
                            <a href="#" className={styles.icon}><i className="fa-brands fa-facebook"></i></a>
                            <a href="#" className={styles.icon}><i className="fa-brands fa-github"></i></a>
                            <a href="#" className={styles.icon}><i className="fa-brands fa-linkedin-in"></i></a>
                        </div>
                        <span>or use your email for registration</span>
                        <input
                            type="text"
                            name="Mail"
                            value={formData.Mail}
                            onChange={handleChange}
                            placeholder="Mail"
                        />
                        <input
                            type="text"
                            name="AccountName"
                            value={formData.AccountName}
                            onChange={handleChange}
                            placeholder="Name"
                        />
                        <input
                            type="password"
                            name="AccountPassword"
                            value={formData.AccountPassword}
                            onChange={handleChange}
                            placeholder="Password"
                        />
                        <button type="submit">Sign Up</button>
                        {message && <p>{message}</p>}
                    </form>
                </div>

                <div className={`${styles['form-container']} ${styles['sign-in']}`}>
                    <form onSubmit={handleSignIn}>
                        <h1>Sign In</h1>
                        <div className={styles['social-icons']}>
                            <a href="#" className={styles.icon}><i className="fa-brands fa-google-plus-g"></i></a>
                            <a href="#" className={styles.icon}><i className="fa-brands fa-facebook"></i></a>
                            <a href="#" className={styles.icon}><i className="fa-brands fa-github"></i></a>
                            <a href="#" className={styles.icon}><i className="fa-brands fa-linkedin-in"></i></a>
                        </div>
                        <span>or use your email password</span>
                        {/* <input
                            type="email"
                            name="Mail"
                            value={formData.Mail}
                            onChange={handleChange}
                            placeholder="Email"
                        /> */}
                        <input
                            type="text"
                            name="AccountName"
                            value={formData.AccountName}
                            onChange={handleChange}
                            placeholder="Name"
                        />
                        <input
                            type="password"
                            name="AccountPassword"
                            value={formData.AccountPassword}
                            onChange={handleChange}
                            placeholder="Password"
                        />
                        <a href="#">Forgot Your Password?</a>
                        <button type="submit">Sign In</button>
                        {message && <p>{message}</p>}
                    </form>
                </div>

                <div className={styles['toggle-container']}>
                    <div className={styles.toggle}>
                        <div className={`${styles['toggle-panel']} ${styles['toggle-left']}`}>
                            <h1>Welcome Back!</h1>
                            <p>Enter your personal details to use all of the site's features</p>
                            <button className={styles.hidden} id="login" onClick={handleLoginClick}>Sign In</button>
                            <Link to="/home"><button className={styles.hidden}>Back</button></Link>
                        </div>
                        <div className={`${styles['toggle-panel']} ${styles['toggle-right']}`}>
                            <h1>Hello, Friend!</h1>
                            <p>Register with your personal details to use all of the site's features</p>
                            <button className={styles.hidden} id="register" onClick={handleRegisterClick}>Sign Up</button>
                            <Link to="/home"><button className={styles.hidden}>Back</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
