import React, { useState, useEffect } from 'react';

function TourList() {
    const [tourData, setTourData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [toursPerPage] = useState(5); // Số lượng tour trên mỗi trang
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchTourData();
    }, []);

    // Hàm lấy dữ liệu tour từ API
    const fetchTourData = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://api.gamehay.id.vn/api/tourdl');
            if (!response.ok) {
                throw new Error('Lỗi khi gọi API');
            }
            const data = await response.json();
            // Sắp xếp dữ liệu theo IdTour giảm dần
            const sortedData = data.sort((a, b) => b.TourId - a.TourId);
            setTourData(sortedData);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Hàm cập nhật trạng thái của tour dựa trên TourId
    const handleUpdateStatus = async (TourId) => {
        const confirmUpdate = window.confirm('Bạn có chắc chắn muốn đổi trạng thái của tour này thành off?');
        if (confirmUpdate) {
            try {
                const response = await fetch(`https://api.gamehay.id.vn/api/tourdl/${TourId}`, {
                    method: 'PUT',
                });
                if (!response.ok) {
                    throw new Error('Lỗi khi cập nhật trạng thái tour');
                }
                // Cập nhật thành công, cập nhật lại danh sách tour
                setTourData(tourData.map((tour) =>
                    tour.TourId === TourId ? { ...tour, TrangThai: 'off' } : tour
                ));
                alert('Đã cập nhật trạng thái tour thành công!');
            } catch (err) {
                alert('Đã xảy ra lỗi khi cập nhật trạng thái tour: ' + err.message);
            }
        }
    };


    // Lọc dữ liệu theo từ khóa tìm kiếm
    // Lọc dữ liệu theo từ khóa tìm kiếm và trạng thái 'on'
    const filteredTours = tourData.filter(tour =>
        tour.TrangThai === 'on' && tour.TourName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Lấy dữ liệu tour cho trang hiện tại
    const indexOfLastTour = currentPage * toursPerPage;
    const indexOfFirstTour = indexOfLastTour - toursPerPage;
    const currentTours = filteredTours.slice(indexOfFirstTour, indexOfLastTour);

    // Thay đổi trang
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    if (loading) {
        return <p>Đang tải dữ liệu...</p>;
    }

    if (error) {
        return <p>Có lỗi xảy ra: {error}</p>;
    }

    return (
        <div className="container">
            <h2>Danh sách Tour Du Lịch</h2>

            {/* Thanh tìm kiếm */}
            <input
                type="text"
                placeholder="Tìm kiếm tour..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control mb-3"
            />

            {/* Bảng dữ liệu */}
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tên Tour</th>
                        <th>Giá Tour</th>
                        <th>Thời gian</th>
                        <th>Số ngày</th>
                        <th>Chi tiết</th>
                        <th>Giới thiệu</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {currentTours.length > 0 ? (
                        currentTours.map((tour) => (
                            <tr key={tour.TourId}>
                                <td>{tour.TourId}</td>
                                <td>{tour.TourName}</td>
                                <td>{tour.TourPrice}</td>
                                <td>{new Date(tour.TourTime).toLocaleDateString('vi-VN')}</td>
                                <td>{tour.TourDay}</td>
                                <td>{tour.TourDetail}</td>
                                <td>{tour.TourIntroduce}</td>
                                <td>
                                    {/* Nút Xóa */}
                                    <button
                                        onClick={() => handleUpdateStatus(tour.TourId)}
                                        className="btn btn-danger"
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="8">Không có dữ liệu</td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Phân trang */}
            <nav>
                <ul className="pagination">
                    {Array.from({ length: Math.ceil(filteredTours.length / toursPerPage) }, (_, i) => (
                        <li key={i + 1} className="page-item">
                            <button onClick={() => paginate(i + 1)} className="page-link">
                                {i + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
}

export default TourList;
