import "./ImageSlider.css";
import React, { useState, useEffect } from "react";
import TourForm from "./tableTour/TourForm";
import TourList from "./tableTour/TourList";
import KhuVucList from "./tableKV/KhuVucList";
import KhuVucForm from "./tableKV/KhuVucForm";
import QuocGiaForm from "./tableQG/QuocGiaForm";
import KhachHangList from "./tableKH/KhachHangList";
import AddKhachHang from "./tableKH/AddKhachHang";
import AccountList from "./tableAcc/AccountList";
import CartDetails from "./CartDetails";


function Profile() {
    const [selectedPoint, setSelectedPoint] = useState(1);
    const [khachhang, setKH] = useState([]);
    const [userID, setUser] = useState(null);
    const [acc, setAC] = useState(null);
    const [role, setRole] = useState(null);
    const [formData, setFormData] = useState({
        TenKH: '',
        Email: '',
        SDT: '',
        // NgaySinh: { ngay: '', thang: '', nam: '' },
        DiaChi: '',
        NoiDung: '',
        AccountID: '',
    });

    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/khachhang")
            .then((response) => response.json())
            .then((data) => {
                setKH(data);
            })
            .catch((error) => console.error("Lỗi:", error));
    }, []);
    //lấy dữ liệu account từ từ dữ liệu IdAccount đã lưu
    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/account")
            .then((response) => response.json())
            .then((data) => {
                setAC(data);
                // Tìm tài khoản dựa trên userID và cập nhật role
                const userAccount = data.find(account => account.ID === userID);
                if (userAccount) {
                    setRole(userAccount.Role);
                }
            })
            .catch((error) => console.error("Lỗi:", error));
    }, [userID]);
    console.log(role)
    //lấy dữ IdAccount đã lưu khi đăng nhập
    useEffect(() => {
        // Khi component được gắn kết, lấy dữ liệu người dùng từ localStorage
        try {
            const userInfo = localStorage.getItem('userInfo');
            if (userInfo) {
                const userData = JSON.parse(userInfo);
                setUser(userData.ID);
                console.log(userData.ID)
                // Cập nhật formData với dữ liệu người dùng
                setFormData({
                    Email: userData.Mail || '',
                    AccountID: userData.ID || '',
                });
            }
        } catch (error) {
            console.error('Error parsing userInfo from localStorage:', error);
        }
    }, []);
    // kiểm tra IdUser đã đăng nhập và lấy dữ liệu người dùng từ bảng khachhang
    useEffect(() => {
        // Cập nhật formData khi filteredKH thay đổi
        const filteredKH = khachhang.find((kh) => kh.AccountID === userID);
        if (filteredKH) {
            setFormData({
                TenKH: filteredKH.TenKH || '',
                Email: filteredKH.Email || '',
                SDT: filteredKH.SDT || '',
                NgaySinh: filteredKH.NgaySinh || { ngay: '', thang: '', nam: '' },
                DiaChi: filteredKH.DiaChi || '',
                NoiDung: filteredKH.NoiDung || '',
                AccountID: filteredKH.AccountID || '',
            });
        }
    }, [khachhang, userID]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     // Cập nhật giá trị ngày, tháng, năm vào formData
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         NgaySinh: {
    //             ...prevFormData.NgaySinh,
    //             [name]: value
    //         }
    //     }));
    // };
    //lấy hoặc lưu dữ liệu người dùng
    const handleSubmit = async (e) => {
        e.preventDefault();

        // const { ngay = '01', thang = '01', nam = '1900' } = formData.NgaySinh;

        // // Đảm bảo các giá trị ngày, tháng, năm luôn là chuỗi và có độ dài 2 ký tự cho ngày/tháng, 4 ký tự cho năm
        // const ngaySinh = `${nam}-${thang.padStart(2, '0')}-${ngay.padStart(2, '0')}`;

        const existingKH = khachhang.find((kh) => kh.AccountID === userID);

        try {
            const response = await fetch(
                existingKH ? `https://api.gamehay.id.vn/api/khachhang/${formData.AccountID}` : 'https://api.gamehay.id.vn/api/khachhang',
                {
                    method: existingKH ? 'PUT' : 'POST', // PUT nếu đã có khách hàng, POST nếu chưa có
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...formData,
                        // NgaySinh: ngaySinh, // Gửi NgaySinh đã được định dạng
                    }),
                }
            );

            const result = await response.json();

            if (response.ok) {
                alert(result.message);

                // Cập nhật lại dữ liệu khách hàng từ server
                fetch("https://api.gamehay.id.vn/api/khachhang")
                    .then((response) => response.json())
                    .then((data) => {
                        setKH(data);
                        // Cập nhật lại formData với dữ liệu mới từ server
                        const updatedKH = data.find((kh) => kh.AccountID === formData.AccountID);
                        if (updatedKH) {
                            // Tách NgaySinh thành ngày, tháng, năm
                            // const [nam, thang, ngay] = updatedKH.NgaySinh.split('-');
                            setFormData({
                                ...updatedKH,
                                // NgaySinh: {
                                //     ngay: ngay || '',
                                //     thang: thang || '',
                                //     nam: nam || '',
                                // }
                            });
                        }
                    })
                    .catch((error) => console.error("Lỗi khi cập nhật dữ liệu khách hàng:", error));
            } else {
                alert(result.error);
            }
        } catch (error) {
            console.error('Lỗi khi gửi dữ liệu:', error);
            alert('Đã xảy ra lỗi khi gửi dữ liệu');
        }
    };

    // const generateOptions = (start, end) => {
    //     const options = [];
    //     for (let i = start; i <= end; i++) {
    //         options.push(<option key={i} value={i}>{i}</option>);
    //     }
    //     return options;
    // };
    const filteredKH = khachhang.find((kh) => kh.AccountID === userID);
    return (
        <div className="container light-style flex-grow-1 container-p-y">
            <h4 className="font-weight-bold py-3 mb-4">
                Tài Khoản
            </h4>
            <div className="card overflow-hidden">
                <div className="row no-gutters row-bordered row-border-light">
                    <div className="col-md-3 pt-0">
                        <div className="list-group list-group-flush account-settings-links">
                            <a className="list-group-item list-group-item-action active" data-toggle="list" href="#account-general">Hồ sơ</a>
                            <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-change-password">Thay đổi mật khẩu</a>
                            <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-social-links">Mạng xã hội</a>
                            <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-connections">Liên kết</a>
                            <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-cart">Giỏ hàng</a>
                            {role === "admin" &&
                                (<a className="list-group-item list-group-item-action" data-toggle="list" data-target="#account-manager" aria-controls="account-manager">
                                    Quản lý
                                </a>)
                            }
                            <div id="account-manager" className="collapse">
                                <div className="list-group list-group-flush account-manager-links">
                                    <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-manager1">Dữ liệu khu vực</a>
                                    <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-manager2">Dữ liệu quốc gia</a>
                                    <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-manager3">Dữ liệu tour du lịch</a>
                                    <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-manager4">Dữ liệu khách hàng</a>
                                    <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-manager5">Dữ liệu tài khoản</a>
                                    <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-manager6">Dữ liệu thanh toán</a>
                                    <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-manager7">Dữ liệu xuất vé</a>
                                    <a className="list-group-item list-group-item-action" data-toggle="list" href="#account-manager8">Dữ liệu thể loại</a>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="col-md-9">
                        <div className="tab-content">
                            <div className="tab-pane fade active show" id="account-general">
                                <div className="card-body media align-items-center">
                                    <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt=""
                                        className="d-block ui-w-80" />
                                    <div className="media-body ml-4">
                                        Đổi ảnh mới
                                        <input type="file" className="account-settings-fileinput" />
                                        &nbsp;
                                        <button type="button" className="btn btn-default md-btn-flat">Tải lại</button>
                                        <div className="text-light small mt-1">Allowed JPG, GIF or PNG. Max size of 800K</div>
                                    </div>
                                </div>
                                <hr className="border-light m-0" />
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <h6 className="mb-4">Thông tin</h6>
                                        <div className="form-group">
                                            Account ID
                                            <input
                                                type="text"
                                                name="AccountID"
                                                placeholder="Chưa có thông tin"
                                                className="form-control mb-1"
                                                value={formData.AccountID}
                                                onChange={handleChange}
                                                required
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-group">
                                            Họ và Tên
                                            <input
                                                type="text"
                                                name="TenKH"
                                                placeholder="Chưa có thông tin"
                                                className="form-control mb-1"
                                                value={formData.TenKH || (filteredKH ? filteredKH.TenKH : '')}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <hr className="border-light m-0" />
                                        <h6 className="mb-4">Liên hệ</h6>
                                        <div className="form-group">
                                            Số điện thoại
                                            <input
                                                type="text"
                                                name="SDT"
                                                placeholder="Chưa có thông tin"
                                                className="form-control mb-1"
                                                value={formData.SDT || (filteredKH ? filteredKH.SDT : '')}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            Email
                                            <input
                                                type="email"
                                                className="form-control mb-1"
                                                value={formData.Email}
                                                name="Email"
                                                placeholder="Email"
                                                onChange={handleChange}
                                                required
                                                readOnly
                                            />
                                            <div className="alert alert-warning mt-3">
                                                Email của bạn chưa được xác minh. Vui lòng kiểm tra hộp thư của bạn.<br />
                                                <a href="">Gửi lại thông báo</a>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            Địa chỉ
                                            <input
                                                type="text"
                                                name="DiaChi"
                                                placeholder="Chưa có thông tin"
                                                className="form-control mb-1"
                                                value={filteredKH ? formData.DiaChi || filteredKH.DiaChi : formData.DiaChi}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <hr className="border-light m-0" />
                                        <h6 className="mb-4">Tiểu sử</h6>
                                        <div className="form-group">
                                            Nội dung
                                            <textarea
                                                name="NoiDung"
                                                placeholder="Chưa có thông tin"
                                                className="form-control mb-1"
                                                value={formData.NoiDung}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="text-right mt-3">
                                            <button type="submit" className="btn btn-primary">Cập nhật thông tin</button>&nbsp;
                                            {/* <button type="button" className="btn btn-default">Cancel</button> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="account-change-password">
                                <div className="card-body pb-2">
                                    <div className="form-group">
                                        Nhập mật khẩu cũ
                                        <input type="password" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        Nhập mật khẩu mới
                                        <input type="password" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        Nhập lại mật khẩu
                                        <input type="password" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="account-social-links">
                                <div className="card-body pb-2">
                                    <div className="form-group">
                                        Twitter
                                        <input type="text" className="form-control" value="https://twitter.com/user" />
                                    </div>
                                    <div className="form-group">
                                        Facebook
                                        <input type="text" className="form-control" value="https://www.facebook.com/user" />
                                    </div>
                                    <div className="form-group">
                                        Google+
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        LinkedIn
                                        <input type="text" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        Instagram
                                        <input type="text" className="form-control" value="https://www.instagram.com/user" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="account-connections">
                                <div className="card-body">
                                    <button type="button" className="btn btn-twitter">Connect to
                                        <strong>Twitter</strong></button>
                                </div>
                                <hr className="border-light m-0" />
                                <div className="card-body">
                                    <h5 className="mb-2">
                                        <a href="" className="float-right text-muted text-tiny"><i
                                            className="ion ion-md-close"></i> Remove</a>
                                        <i className="ion ion-logo-google text-google"></i>
                                        You are connected to Google:
                                    </h5>
                                    <a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                        data-cfemail="f9979498818e9c9595b994989095d79a9694">[email&#160;protected]</a>
                                </div>
                                <hr className="border-light m-0" />
                                <div className="card-body">
                                    <button type="button" className="btn btn-facebook">Connect to
                                        <strong>Facebook</strong></button>
                                </div>
                                <hr className="border-light m-0" />
                                <div className="card-body">
                                    <button type="button" className="btn btn-instagram">Connect to
                                        <strong>Instagram</strong></button>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="account-cart">
                                <CartDetails />
                            </div>
                            <div className="tab-pane fade" id="account-manager1">
                                <KhuVucForm />
                                {/* <button onClick={() => setSelectedPoint(1)} className={selectedPoint === 1 ? 'btn btn-primary' : 'btn btn-secondary'}>
                                    Xem dữ liệu
                                </button>
                                <button onClick={() => setSelectedPoint(2)} className={selectedPoint === 2 ? 'btn btn-primary' : 'btn btn-secondary'}>
                                    Thêm dữ liệu
                                </button> */}
                                {/* {selectedPoint === 1 && (
                                    <KhuVucList />
                                )}

                                {selectedPoint === 2 && (
                                    <KhuVucForm />
                                )} */}
                            </div>
                            <div className="tab-pane fade" id="account-manager2">
                                <QuocGiaForm />
                            </div>
                            <div className="tab-pane fade" id="account-manager3">
                                <button onClick={() => setSelectedPoint(1)} className={selectedPoint === 1 ? 'btn btn-primary' : 'btn btn-secondary'}>
                                    Xem dữ liệu
                                </button>
                                <button onClick={() => setSelectedPoint(2)} className={selectedPoint === 2 ? 'btn btn-primary' : 'btn btn-secondary'}>
                                    Thêm dữ liệu
                                </button>
                                {selectedPoint === 1 && (
                                    <TourList />
                                )}

                                {selectedPoint === 2 && (
                                    <TourForm />
                                )}
                            </div>
                            <div className="tab-pane fade" id="account-manager4">
                                <button onClick={() => setSelectedPoint(1)} className={selectedPoint === 1 ? 'btn btn-primary' : 'btn btn-secondary'}>
                                    Xem dữ liệu
                                </button>
                                <button onClick={() => setSelectedPoint(2)} className={selectedPoint === 2 ? 'btn btn-primary' : 'btn btn-secondary'}>
                                    Thêm dữ liệu
                                </button>
                                {selectedPoint === 1 && (
                                    <KhachHangList />
                                )}

                                {selectedPoint === 2 && (
                                    <AddKhachHang />
                                )}

                            </div>
                            <div className="tab-pane fade" id="account-manager5">
                                <AccountList />
                            </div>
                            <div className="tab-pane fade" id="account-manager6">
                                <h2>Chưa có thông tin</h2>
                            </div>
                            <div className="tab-pane fade" id="account-manager7">
                                <h2>Chưa có thông tin</h2>
                            </div>
                            <div className="tab-pane fade" id="account-manager8">
                                <h2>Chưa có thông tin</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
