import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function TourForm() {
    const [formData, setFormData] = useState({
        TourName: '',
        TourImage: '',
        TourPrice: '',
        SoLuongVe: '',
        TourTime: null,  // Sử dụng kiểu dữ liệu Date cho thời gian tour
        TourDay: '',
        TourDetail: '',
        TourIntroduce: '',
        TourVideo: '',
        TouKV: '',
        TouQG: '',
        TouTL: '',
    });

    const [khuvuc, setKV] = useState([]);
    const [quocgia, setQG] = useState([]);
    const [theloaidl, setTL] = useState([]);

    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/khuvuc")
            .then((response) => response.json())
            .then((data) => setKV(data))
            .catch((error) => console.error("Lỗi:", error));
    }, []);

    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/quocgia")
            .then((response) => response.json())
            .then((data) => setQG(data))
            .catch((error) => console.error("Lỗi:", error));
    }, []);

    useEffect(() => {
        fetch("https://api.gamehay.id.vn/api/theloaidl")
            .then((response) => response.json())
            .then((data) => setTL(data))
            .catch((error) => console.error("Lỗi:", error));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'TouQG') {
            const [idQG, idKV] = value.split('-');
            setFormData({
                ...formData,
                TouQG: idQG,
                TouKV: idKV,
            });
        }
    };

    const handleDateChange = (date) => {
        // Chỉ lấy ngày (YYYY-MM-DD)
        const formattedDate = date.toISOString().split('T')[0];
        setFormData({
            ...formData,
            TourTime: formattedDate,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api.gamehay.id.vn/api/tourdl', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            if (response.ok) {
                alert(result.message);
                setFormData({
                    TourName: '',
                    TourImage: '',
                    TourPrice: '',
                    SoLuongVe: '',
                    TourTime: null,  // Reset thời gian tour
                    TourDay: '',
                    TourDetail: '',
                    TourIntroduce: '',
                    TourVideo: '',
                    TouKV: '',
                    TouQG: '',
                    TouTL: '',
                });
            } else {
                alert(result.error);
            }
        } catch (error) {
            console.error('Lỗi khi gửi dữ liệu:', error);
            alert('Đã xảy ra lỗi khi gửi dữ liệu');
        }
    };

    return (
        <div className="container">
            <h2>Thêm Tour Du Lịch</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Tên Tour</label>
                    <input
                        type="text"
                        name="TourName"
                        value={formData.TourName}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Hình ảnh Tour</label>
                    <input
                        type="text"
                        name="TourImage"
                        value={formData.TourImage}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Giá Tour</label>
                    <input
                        type="number"
                        name="TourPrice"
                        value={formData.TourPrice}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Số lượng vé</label>
                    <input
                        type="number"
                        name="SoLuongVe"
                        value={formData.SoLuongVe}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Thời gian Tour</label>
                    <DatePicker
                        selected={formData.TourTime ? new Date(formData.TourTime) : null}
                        onChange={handleDateChange}
                        dateFormat="yyyy/MM/dd"
                        className="form-control"
                        placeholderText="Chọn ngày"
                    />
                </div>
                <div className="form-group">
                    <label>Số ngày Tour</label>
                    <input
                        type="number"
                        name="TourDay"
                        value={formData.TourDay}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Chi tiết Tour</label>
                    <textarea
                        name="TourDetail"
                        value={formData.TourDetail}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Giới thiệu Tour</label>
                    <textarea
                        name="TourIntroduce"
                        value={formData.TourIntroduce}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Video Tour</label>
                    <input
                        type="text"
                        name="TourVideo"
                        value={formData.TourVideo}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group">
                    <label>Quốc gia - Khu Vực</label>
                    <select
                        name="TouQG"
                        value={`${formData.TouQG}-${formData.TouKV}`}
                        onChange={handleChange}
                        className="form-control"
                    >
                        <option value="">Chọn quốc gia và khu vực</option>
                        {quocgia.map((qg) => {
                            // Tìm khu vực dựa trên KhuVucId
                            const region = khuvuc.find((kv) => kv.IdKV === qg.KhuVucId);
                            return (
                                <option key={qg.IdQG} value={`${qg.IdQG}-${qg.KhuVucId}`}>
                                    {qg.TenQG} - {region ? region.TenKV : ''}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label>Loại Tour</label>
                    <select
                        name="TouTL"
                        value={formData.TouTL}
                        onChange={handleChange}
                        className="form-control"
                    >
                        <option value="">Chọn loại tour</option>
                        {theloaidl.map((tl) => (
                            <option key={tl.IdTL} value={tl.IdTL}>{tl.TenTL}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">Thêm Tour</button>
            </form>
        </div>
    );
}

export default TourForm;
