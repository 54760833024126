
function Contact() {
  return (
    <div>
      {/* <!-- Contact information--> */}
      <section className="section-sm section-first bg-default">
        <div className="container">
          <div className="row row-30 justify-content-center">
            <div className="col-sm-8 col-md-6 col-lg-4">
              <article className="box-contacts">
                <div className="box-contacts-body">
                  <div className="box-contacts-icon fl-bigmug-line-cellphone55"></div>
                  <div className="box-contacts-decor"></div>
                  <p className="box-contacts-link">
                    <a href="tel:#">+1 323-913-4688</a>
                  </p>
                  <p className="box-contacts-link">
                    <a href="tel:#">+1 323-888-4554</a>
                  </p>
                </div>
              </article>
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <article className="box-contacts">
                <div className="box-contacts-body">
                  <div className="box-contacts-icon fl-bigmug-line-up104"></div>
                  <div className="box-contacts-decor"></div>
                  <p className="box-contacts-link">
                    <a href="#">
                      Phạm Ngũ Lão, Q.1, TP.HCM, Việt Nam
                    </a>
                  </p>
                </div>
              </article>
            </div>
            <div className="col-sm-8 col-md-6 col-lg-4">
              <article className="box-contacts">
                <div className="box-contacts-body">
                  <div className="box-contacts-icon fl-bigmug-line-chat55"></div>
                  <div className="box-contacts-decor"></div>
                  <p className="box-contacts-link">
                    <a href="mailto:#">haiztvn@gmail.com</a>
                  </p>
                  <p className="box-contacts-link">
                    <a href="mailto:#">admin@gamehay.id.vn</a>
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Contact Form--> */}
      <section className="section section-sm section-last bg-default text-left">
        <div className="container">
          <article className="title-classic">
            <div className="title-classic-title">
              <h3>Liên Hệ</h3>
            </div>
            <div className="title-classic-text">
              <p>
                Nếu bạn có bất kỳ câu hỏi nào, chỉ cần điền
                vào biểu mẫu liên hệ và chúng tôi sẽ sớm trả lời bạn.
              </p>
            </div>
          </article>
          <form
            className="rd-form rd-form-variant-2 rd-mailform"
            data-form-output="form-output-global"
            data-form-type="contact"
            method="post"
            action="bat/rd-mailform.php"
          >
            <div className="row row-14 gutters-14">
              <div className="col-md-4">
                <div className="form-wrap">
                  Họ và Tên
                  <input
                    className="form-input"
                    id="contact-your-name-2"
                    type="text"
                    name="name"
                    data-constraints="@Required"
                  />
                  {/* <label className="form-label" htmlFor="contact-your-name-2">
                    Your Name
                  </label> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-wrap">
                  E-mail
                  <input
                    className="form-input"
                    id="contact-email-2"
                    type="email"
                    name="email"
                    data-constraints="@Email @Required"
                  />
                  {/* <label className="form-label" htmlFor="contact-email-2">
                    E-mail
                  </label> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-wrap">
                  Số Điện Thoại
                  <input
                    className="form-input"
                    id="contact-phone-2"
                    type="text"
                    name="phone"
                    data-constraints="@Numeric"
                  />
                  {/* <label className="form-label" htmlFor="contact-phone-2">
                    Phone
                  </label> */}
                </div>
              </div>
              <div className="col-12">
                <div className="form-wrap">
                  Nội dung
                  {/* <label className="form-label" htmlFor="contact-message-2">
                    Message
                  </label> */}
                  <textarea
                    className="form-input textarea-lg"
                    id="contact-message-2"
                    name="message"
                    data-constraints="@Required"
                  ></textarea>
                </div>
              </div>
            </div>
            <button
              className="button button-primary button-pipaluk"
              type="submit"
            >
              Gửi Ngay
            </button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Contact;
