import { Link, useLocation, Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import HeaderPage from "./HeaderPage";
import Banner from "./Banner";
import Home from "./Home";
import Footer from "./Footer";
import Contact from "./Contact";
import Properties from "./Properties1";

function Page() {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState("");

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    if (activeLink === "/home")
        return (
            <div>
                <Banner />
                <Home />
            </div>
        );
    else if (activeLink === "/contact")
        return (
            <div>
                <Banner />
                <Contact />
            </div>
        );
    else if (activeLink === "/properties")
        return (
            <div>
                <Banner />
                <Properties />
            </div>
        );
}

export default Page;

