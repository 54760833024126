import HeaderPage from "./HeaderPage.js";
import { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import React from "react";
function PropertyDetails() {
  const { id } = useParams(); // Lấy id từ địa chỉ URL
  const [products, setPr] = useState([]);
  const [theloaidl, setTL] = useState([]);
  const [khachhangs, setKH] = useState([]);
  const [userID, setUser] = useState(null);
  const [formData, setFormData] = useState({
    TourId: '',
    KhachHangId: '',
  });

  useEffect(() => {
    fetch("https://api.gamehay.id.vn/api/tourdl")
      .then((response) => response.json())
      .then((data) => {
        setPr(data);
      })
      .catch((error) => console.error("Lỗi:", error));
  }, []);
  useEffect(() => {
    fetch("https://api.gamehay.id.vn/api/theloaidl")
      .then((response) => response.json())
      .then((data) => {
        setTL(data);
      })
      .catch((error) => console.error("Lỗi:", error));
  }, []);
  useEffect(() => {
    fetch('https://api.gamehay.id.vn/api/khachhang')
      .then((response) => response.json())
      .then((data) => setKH(data))
      .catch((error) => console.error('Error fetching customers:', error));
  }, []);

  const handleAddToCart = async (e) => {
    e.preventDefault();

    // Cập nhật formData với TourId và KhachHangId
    setFormData((prevFormData) => ({
      ...prevFormData,
      TourId: product?.TourId || '',    // Lấy TourId từ product
      KhachHangId: khachhang?.IdKH || '' // Lấy KhachHangId từ khachhang
    }));

    try {
      const response = await fetch('https://api.gamehay.id.vn/api/chitietgiohang', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          TourId: product?.TourId || '',
          KhachHangId: khachhang?.IdKH || ''
        }),
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
        // Reset form data sau khi thêm thành công
        setFormData({
          TourId: '',
          KhachHangId: '',
        });
      } else {
        console.error('Lỗi khi thêm vé vào giỏ hàng:', data.error);
        alert('Có lỗi xảy ra: ' + data.error);
      }
    } catch (error) {
      console.error('Lỗi khi thêm vé vào giỏ hàng:', error);
      alert('Đã xảy ra lỗi trong quá trình thêm vé.');
    }
  };


  //lấy dữ IdAccount đã lưu khi đăng nhập
  useEffect(() => {
    // Khi component được gắn kết, lấy dữ liệu người dùng từ localStorage
    try {
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        const userData = JSON.parse(userInfo);
        setUser(userData.ID);
        console.log(userData.ID)
        // Cập nhật formData với dữ liệu người dùng
        setFormData({
          Email: userData.Mail || '',
          AccountID: userData.ID || '',
        });
      }
    } catch (error) {
      console.error('Error parsing userInfo from localStorage:', error);
    }
  }, []);
  // Tìm sản phẩm có id tương ứng
  const product = products.find((product) => product.TourId === parseInt(id));
  const khachhang = khachhangs.find((kh) => kh.AccountID === userID);
  if (!product) {
    return <div>Sản phẩm không tồn tại</div>;
  }
  return (
    <div>
      <div className="best-deal">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-content">
                <div className="row">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="appartment"
                      role="tabpanel"
                      aria-labelledby="appartment-tab"
                    >
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="info-table">
                            <ul>
                              <li>
                                Thời hạn <span>{new Date(product.TourTime).toLocaleDateString('vi-VN')}</span>
                              </li>
                              <li>
                                Số ngày đi <span>{product.TourDay}</span>
                              </li>
                              <li>
                                Giá <span>${product.TourPrice}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="single-property section">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-8">
                                  <div className="main-image">
                                    <img src={require(`./images1/tourdl/${product.TourImage}`)} alt="" />
                                  </div>
                                  <div className="main-content">
                                    <span className="category">{
                                      theloaidl.find((theloai) => theloai.IdTL === product.TouTL)?.TenTL || "Thể loại không xác định"
                                    }</span>
                                    <h4>{product.TourName}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <h4>{product.TourName}</h4>
                          <p>
                            {product.TourDetail}
                          </p>
                          <div className="icon-button">
                            <a href="#" onClick={handleAddToCart}>
                              <i className="fa fa-calendar"></i>
                              Đặt ngay
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyDetails;
